import VehicleSelector from './vue/Vehicle/VehicleSelector';
import VehicleSelectorCompact from './vue/Vehicle/VehicleSelectorCompact';
import ProductList from './vue/Product/ProductList';
import { createApp } from "vue";

import ShoppingCartDrawer from './modules/shopping_cart_drawer';

// Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css';


/*

  Vue

  */
createApp(VehicleSelector).mount('#vehicle-selector');
createApp(VehicleSelectorCompact).mount('#vehicle-selector-compact');
createApp(ProductList).mount('#product-list');




document.addEventListener('DOMContentLoaded', function () {
  document.querySelector('#navbarCollapse .navbar-nav:not(.navbar-mega-nav)').addEventListener('click', function (e) {
    if (e.target.classList.contains('dropdown-toggle')) {
      var parentLink = e.target;
      var dropdownMenu = parentLink.nextElementSibling;

      if (window.innerWidth >= 992) {
        // On larger screens, follow the link
        window.location.href = parentLink.href;
      } else {
        // On smaller screens, toggle the dropdown
      }
    }
  });
});


// TODO : Move this to a separate file
document.querySelectorAll('.vehicle-reset').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    fetch('/ymm-json.php?request=reset').then((response) => {
      let url = new URL(window.location.href);
      url.searchParams.delete('class_9');
      url.searchParams.delete('class_8');
      url.searchParams.delete('class_year');
      window.location.replace(url.toString());
      window.location = "/";

    });
  });
});

ShoppingCartDrawer.init('cart-drawer');



// let priceBtn = document.getElementById("priceRangeBtn");
// let priceRangeMin = document.getElementById("priceRangeMin");
// let priceRangeMax = document.getElementById("priceRangeMax");

// if (priceBtn != null) {
//   priceBtn.addEventListener("click", function () {
//     let priceRange = priceRangeMin.value + "-" + priceRangeMax.value;

//     let searchParams = new URLSearchParams(window.location.search);

//     searchParams.set('price', priceRange);

//     let newUrl = window.location.pathname + '?' + searchParams.toString();

//     window.location = newUrl;
//   });
// }



/**
 * ********************** vr23 Footer Newsletter form submit ************************/
const SubmitNewsletterForm = function (event) {
  event.preventDefault();

  let formData = new FormData(event.target);
  const formParams = new URLSearchParams(formData);
  const errorToast = new bootstrap.Toast(document.getElementById('vr23_footer_embed_signup_error'));
  fetch('/newsletter/submit-new', {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'},
    body: formParams,
    cache: 'default'
  }).then(resp => {
    if (!resp.ok) {
      throw new Error();
    }

    document.getElementById('vr23_footer_embed_signup').innerHTML = '<p class="text-light">Thank you! Check your email for special offers</p>';

  }).catch(err => {
    errorToast.show();
  });
}

document.getElementById('vr23_footer_embed_signup').addEventListener('submit', SubmitNewsletterForm);
/************************ end vr23 Footer Newsletter form submit ************************/

/**
 * *********** Open Sidebar Cart instead of sending to Shopping Cart page *************/
const ShoppingCartContentEvent = new CustomEvent('shopping_cart_contents_changed', {
  bubbles: true,
  detail: {
    qty: 0,
    subtotal: 0,
    products: {},
  }
});

const openCartSideBar = (event) => {
  event.preventDefault();

  let formData = new FormData();
  formData.append('is_silent', "1");
  const formParams = new URLSearchParams(formData);

  const errorToast = new bootstrap.Toast(document.getElementById('add_to_cart_error'));
  fetch(event.currentTarget.href, {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'},
    body: formParams,
    cache: 'default'
  }).then(resp => resp.json()).then((data) => {
    if (data.hasOwnProperty('response')) {
      if (data.response.success) {
        if (data.response.hasOwnProperty('redirect_url')) {
          window.location.href = data.response.redirect_url;
        } else {
          ShoppingCartContentEvent.detail.qty         = data.response.total_products_count;
          ShoppingCartContentEvent.detail.subtotal    = data.response.subtotal_int;
          ShoppingCartContentEvent.detail.products    = data.response.cart_products;
          window.dispatchEvent(ShoppingCartContentEvent);
        }
      } else {
        errorToast.show();
      }
    } else {
      errorToast.show();
    }
  }).catch(err => {
    errorToast.show();
  });
}

function setShoppingCartExperience() {
  const buyNowLinks = document.getElementsByClassName('buy_now_link');
  if (buyNowLinks === null || buyNowLinks.length === 0) {
    return;
  }

  if (ADD_TO_CART_GO_CHECKOUT === undefined || ADD_TO_CART_GO_CHECKOUT) {
    return;
  }

  for (let ii = 0; ii < buyNowLinks.length; ii++) {
    buyNowLinks[ii].addEventListener('click', openCartSideBar, {capture: true});
  }
}
document.addEventListener("DOMContentLoaded", setShoppingCartExperience);
/************* end Open Sidebar Cart instead of sending to Shopping Cart page *************/
<template>
	<div class="card product-card shadow-none">
		<!-- Badges Start -->
		<div class="position-absolute d-flex width-100 flex-wrap gap-2" style="left: .75rem; top: .75rem;z-index: 5;">
		<span class="badge bg-danger in-stock-indicator" v-if="isLowStock"><i class="ci-announcement me-1"
			   aria-hidden="true"></i> Hurry Low Stock</span>
		<span v-else-if="product.in_stock" class="badge bg-success  in-stock-indicator"><i class="ci-check-circle me-1"
			   aria-hidden="true"></i> In Stock</span>
		<span v-if="product.manufacturers_id == 3785" class="badge bg-success  in-stock-indicator"><i class="ci-phone me-1"
			   aria-hidden="true"></i> Call for
			Availability</span>
		<span v-if="product.free_shipping && !isWholesale" class="badge bg-success  free-shipping-indicator"><i
			   class="ci-truck me-1" aria-hidden="true"></i> Free Shipping</span>
		<span v-if="product.active_promo_shipping" class="promoShipping badge bg-success ">
			<i class="ci-truck me-1" aria-hidden="true"></i>
			<span> Promo Shipping</span>
		</span>
	</div>
		<!-- Badges End -->
		<a :href="`/?action=wishlist.add&amp;pid=${product.products_id}`" class="btn-wishlist btn-sm" type="button"
		   data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Add to wishlist"
		   data-bs-original-title="Add to wishlist" @click.prevent="handleWishlistClick('add')"
    ><i class="ci-heart"></i></a>

		<a class="card-img-top d-block overflow-hidden" :href="product.link">
			<img :src="productImage" :alt="product.products_name" loading="lazy">
		</a>
		<div class="card-body py-2"><a class="product-meta d-block fs-xs pb-1" href="#">{{ product.manufacturers_name }}</a>
			<h3 class="product-title fs-sm"><a :href="product.link">{{ product.products_name }}</a></h3>
			<div class="d-flex justify-content-between">
				<div>
					<span class="badge bg-secondary text-muted model-number">
						#{{ product.products_model }}
					</span>
				</div>
				<div class="product-price"><span class="text-accent" v-html="formattedPrice">
					</span>
				</div>

			</div>
		</div>

		<div class="card-body card-body-hidden">
			<a class="buy_now_link"
			   :href="`/-p-${product.products_id}.html?action=buy_now&products_id=${product.products_id}`">
				<button type="button" class="btn btn-success btn-sm d-block w-100 mb-2">
					<i class="ci-cart fs-sm me-1"></i>{{ buttonTitle }}</button>
			</a>
		</div>

		<a class="buy_now_link" :href="`/-p-${product.products_id}.html?action=buy_now&products_id=${product.products_id}`">
			<button class="btn btn-success btn-sm d-block w-100 mb-md-2 d-md-none py-2" type="button">
				<i class="ci-cart fs-sm me-1"></i>{{ buttonTitle }}</button>
		</a>
	</div>
</template>

<script>
export default {
	name: 'ProductCard',
	props: {
		product: {
			type: Object,
			required: true
		},
		isWholesale: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		return {
			product: props.product
		}
	},
	mounted() {
		// console.log(this.product);
	},
  methods: {
    handleWishlistClick(action) {
      const productInfo = {
        $value: this.product.final_price,
        Price: this.product.final_price,
        ProductID: this.product.products_id,
        ProductName: this.product.products_name,
        CategoryNames: this.product.category_names,
        CategoryIDs: this.product.category_ids,
        ImageURL: this.product.image_url,
        URL: window.location.href,
        Manufacturer: this.product.manufacturers_name,
        ProductModel: this.product.products_model,
      };

      if (action === "add") {
        klaviyo.push(["track", "Added to Wishlist", productInfo]);
        console.log("Added to Wishlist", productInfo);
        setTimeout(() => {
          window.location.href = `/?action=wishlist.add&pid=${this.product.products_id}`;
        }, 1000);
      } else if (action === "remove") {
        klaviyo.push(["track", "Removed from Wishlist", productInfo]);
        console.log("Removed from Wishlist", productInfo);
        setTimeout(() => {
          window.location.href = `/?action=wishlist.remove&pid=${this.product.products_id}`;
        }, 1000);
      }
    },
  },
	computed: {
		isLowStock() {

			return this.product.low_stock ?? false;
		},
		isFreeShipping() {
			return this.product.free_shipping ?? false;
		},
		buttonTitle() {
			return this.product['has_options'] || this.product.has_vendor_options ? 'Buy Now' : 'Add to Cart';
		},
		productImage() {
			// Hardcoded for now

			return `https://cdn.vividracing.com/file/${this.product.products_image_lrg}?p=thumb`
		},
		formattedPrice() {
			let priceData = this.product.priceData;

			let output = '';

			if (priceData.isCoupon){
				output += `
				<s class="text-muted">${priceData.fullPrice}</s>
				<span style="color: green;" itemprop="price">${priceData.discountPrice}</span>
				<br/>
				<small class="text-nowrap float-end" style="font-size:10px;" title="Discounted"><span class="px-2 py-1 bg-success rounded text-white"><i class="bi bi-tag"></i>&nbsp;${this.product.coupon?.coupon_code}</span></small>
				`
			}
			else if (priceData.isSale) {
				output += `
				<s class="text-muted">${priceData.fullPrice}</s>
				<span itemprop="price">${priceData.finalPrice}</span>`
			
			}else{
				output += `<span itemprop="price">${priceData.finalPrice}</span>`
			}


			return output;
		}
	}
}
</script>

<style lang="scss" scoped>

.model-number {
	// font-size: 12px;
	text-wrap:balance;
	text-align: left;
}

</style>